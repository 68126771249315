import React from "react";
import bar3 from "../img/gauge_art_dot.png";
import bar1 from "../img/gauge_history_dot.png";
import bar2 from "../img/gauge_literature_dot.png";
import bar6 from "../img/gauge_religion_dot.png";
import bar4 from "../img/gauge_science_dot.png";
import bar5 from "../img/gauge_social_dot.png";

const btnStyle = {
  color: "white",
  background: "#004c88",
  padding: ".2rem .75rem",
  border: "2px solid #000000",
  borderRadius: "1.25rem",
  width: 70,
  fontSize: "1rem",
  lineHeight: 1.5,
  display: "flex",
  justifyContent: "center",
  margin: "0 auto",
  transform: `translate(0%, 10%)`,
};

function Gaugebar(props) {
  return (
    <div style={{ transform: `translate(0%, -19%)` }}>
      <br />
      {props.index <= 6 ? (
        <img
          alt="역사"
          src={bar1}
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "0 auto",
            width: "85%",
          }}
        />
      ) : props.index <= 12 ? (
        <img
          alt="문학"
          src={bar2}
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "0 auto",
            width: "85%",
          }}
        />
      ) : props.index <= 18 ? (
        <img
          alt="예술"
          src={bar3}
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "0 auto",
            width: "85%",
          }}
        />
      ) : props.index <= 24 ? (
        <img
          alt="과학"
          src={bar4}
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "0 auto",
            width: "85%",
          }}
        />
      ) : props.index <= 30 ? (
        <img
          alt="사회"
          src={bar5}
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "0 auto",
            width: "85%",
          }}
        />
      ) : props.index <= 36 ? (
        <img
          alt="종교"
          src={bar6}
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "0 auto",
            width: "85%",
          }}
        />
      ) : (
        0
      )}
      <div style={btnStyle}>
        {props.index <= 6
          ? "역사"
          : props.index <= 12
          ? "문학"
          : props.index <= 18
          ? "예술"
          : props.index <= 24
          ? "과학"
          : props.index <= 30
          ? "사회"
          : props.index <= 36
          ? "종교"
          : 0}{" "}
        {props.index <= 6
          ? props.index - 1
          : props.index <= 12
          ? props.index - 7
          : props.index <= 18
          ? props.index - 13
          : props.index <= 24
          ? props.index - 19
          : props.index <= 30
          ? props.index - 25
          : props.index <= 36
          ? props.index - 31
          : 0}
        /5
      </div>
      <br />
    </div>
  );
}

export default Gaugebar;
