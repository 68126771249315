import React from "react";
import { useLocation } from "react-router-dom";
import loading from "../gif/result_loding.gif";
import background from "../img/bg_pattern1.jpg";

const Loading = (props) => {
  const location = useLocation();
  setTimeout(() => {
    props.history.push({
      pathname: "/result",
      state: { answer: location.state.answer },
    });
  }, 1000);
  return (
    <>
     <div
        style={{
          maxWidth: 410,
          backgroundImage: "url(" + background + ")",
          height: "100%",
          width: "100%",
          position: "absolute",
          left: "50%",
          transform: `translate(-50%, 0%)`,
        }}
      >
      <div style={{ flexBasis: "100%", height: 80 }} />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "0 auto",
            fontSize: 25,
            fontWeight: 800,
          }}
        >
          드디어 끝!
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "0 auto",
            fontSize: 25,
            fontWeight: 800,
          }}
        >
          결과가 나오고 있어요
        </div>
        <br />
        <br />
        <img
          alt="로딩중"
          style={{
            width: "55%",
            display: "flex",
            justifyContent: "center",
            margin: "0 auto",
          }}
          src={loading}
        />
        <br />
        <div
        style={{
          backgroundImage: "url(" + background + ")",
          height: "100%",
          width: "100%",
          fontFamily: "Noto Sans CJK KR",
          fontWeight: "450",
          position: "absolute",
          left: "50%",
          top: "100%",
          transform: `translate(-50%, -100%)`,
          zIndex: -1,
        }}
      ></div>
        </div>
    </>
  );
};
export default Loading;
