import React from "react";
import Home from "./pages/Home";
import QuizPage from "./pages/QuizPage";
import Loading from "./pages/Loading";
import Result from "./pages/Result";

import {
  BrowserRouter,
  Route,
} from "react-router-dom";
function App() {
  return (
    <>
      <BrowserRouter>
        <Route exact path="/" component={Home} />
        <Route
          exact
          path="/quiz/:index"
          render={(props) => (
            <QuizPage index={props.match.params.index} {...props} />
          )}
        />
        <Route exact path="/loading" component={Loading} />
        <Route exact path="/result" component={Result} />
      </BrowserRouter>
    </>
  );
}

export default App;
