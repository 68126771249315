import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import mainTitle from "../img/main_title.png";
import mainTitleKakao from "../img/main_title.png";
import mainStartbtn from "../img/main_startbtn.png";
import mainCharacter2 from "../img/main_character2.png";

import Media from "react-media";

import shareIcon1 from "../img/shareicon_1.png";
import shareIcon2 from "../img/shareicon_2.png";
import shareIcon3 from "../img/shareicon_3.png";
import shareIcon4 from "../img/shareicon_4.png";
import shareIcon5 from "../img/shareicon_5.png";

import homeIcon from "../img/btn_home2.png";

import { CopyToClipboard } from "react-copy-to-clipboard";

const MEDIA_QUERIES = {
  pc: "(min-width:500px)",
  mobile: "(max-width: 499px)",
};

const Home = () => {
  window.scrollTo(0, 0);
  useEffect(() => {
    window.Kakao.init("39ff4b8354a51b8c30a8696c254ad4cc");
  }, []);
  const shareFacebook = () => {
    window.open(
      "https://www.facebook.com/sharer/sharer.php?u=https://quiz.scjandrew.net/"
    );
  };
  const shareTwitter = () => {
    window.open(
      "https://www.twitter.com/intent/tweet?&url=https://quiz.scjandrew.net/"
    );
  };
  const shareKakaotalk = () => {
    window.Kakao.Link.sendDefault({
      objectType: "feed",
      content: {
        title: "알잘딱깔센 상식테스트",
        description: "",
        imageUrl: mainTitleKakao,
        link: {
          mobileWebUrl: "https://quiz.scjandrew.net/",
          androidExecParams: "",
        },
      },
      buttons: [
        {
          title: "웹으로 이동",
          link: {
            mobileWebUrl: "https://quiz.scjandrew.net/",
          },
        },
      ],
    });
  };
  const shareNaver = () => {
    window.open(
      "https://share.naver.com/web/shareView.nhn?url=https://quiz.scjandrew.net/&title=알잘딱깔센상식테스트"
    );
  };
  return (
    <div>
      <div
        style={{
          maxWidth: 410,
          height: "105%",
          width: "100%",
          fontFamily: "Noto Sans CJK KR",
          background: "#a4d6f9",
          fontWeight: "450",
          position: "absolute",
          left: "50%",
          transform: `translate(-50%, 0%)`,
        }}
      >
        <div style={{ flexBasis: "100%", height: 5 }} />

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "0 auto",
            width: "87%",
            marginTop: "10px",
          }}
        >
          <a
            href="https://www.wordsquare.org/"
            style={{
              display: "flex",
              marginLeft: "auto",
              width: "25%",
              height: "25%",
              zIndex: 1,
            }}
          >
            <img alt="말씀광장으로" src={homeIcon} style={{ width: "100%" }} />
          </a>
        </div>
        <div style={{ flexBasis: "100%", height: 20 }} />

        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            alt="알잘딱깔센 상식 테스트"
            src={mainTitle}
            style={{ width: "62%" }}
          />
        </div>
        <br />

        <Media queries={MEDIA_QUERIES}>
          {(m) => {
            return (
              <>
                {m.pc && (
                  <div style={{ fontSize: 15 }}>
                    <div
                      style={{
                        width: "300%",
                        position: "absolute",
                        left: "150%",
                        transform: `translate(-41%, 0%)`,
                      }}
                    >
                      나는 어떤 분야에 강하고 약할까?
                    </div>
                    <br />
                    <div
                      style={{
                        position: "absolute",
                        left: "50%",
                        transform: `translate(-50%, 0%)`,
                      }}
                    >
                      그거슬 알려드림
                    </div>
                    <br />
                    <br />
                  </div>
                )}
                {m.mobile && (
                  <div style={{ fontSize: 15 }}>
                    <div
                      style={{
                        width: "200%",
                        position: "absolute",
                        left: "100%",
                        transform: `translate(-37.5%, 0%)`,
                      }}
                    >
                      나는 어떤 분야에 강하고 약할까?
                    </div>
                    <br />
                    <div
                      style={{
                        position: "absolute",
                        left: "50%",
                        transform: `translate(-50%, 0%)`,
                      }}
                    >
                      그거슬 알려드림
                    </div>
                    <br />
                    <br />
                  </div>
                )}
              </>
            );
          }}
        </Media>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img alt="캐릭터" style={{ width: "75%" }} src={mainCharacter2} />
        </div>
        <br />
        <Link
          to={{ pathname: "quiz/1", state: { answer: "" } }}
          style={{
            display: "flex",
            margin: "0 auto",
            width: "70%",
            transform: `translate(0%, -50%)`,
          }}
        >
          <img
            alt="테스트 시작하기"
            src={mainStartbtn}
            style={{ width: "100%" }}
          />
        </Link>
        {/*<div
          style={{
            display: "flex",
            justifyContent: "center",
            transform: `translate(0%, -50%)`,
          }}
        >
          <img style={{ width: "60%" }} src={count} />
        </div>*/}
        <div
          style={{
            width: "100%",
            position: "flex",
            justifyContent: "center",
            margin: "0 auto",
            background: "white",
            paddingTop: 0,
            paddingBottom: 30,
          }}
        >
          <div style={{ flexBasis: "100%", height: 5 }} />
          <div
            style={{
              width: "100%",
              margin: "0 auto",
              display: "flex",
              justifyContent: "center",
              fontFamily: "Noto Sans CJK KR",
              fontSize: 15,
              fontWeight: 800,
            }}
          >
            테스트 공유하기
          </div>

          <div style={{ flexBasis: "100%", height: 12 }} />
          <div
            style={{
              width: "100%",
              margin: "0 auto",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              onClick={() => shareFacebook()}
              style={{ width: "10%", height: "10%", cursor: "pointer" }}
              src={shareIcon1}
            />
            <div style={{ width: 7 }} />

            <img
              onClick={() => shareTwitter()}
              style={{ width: "10%", height: "10%", cursor: "pointer" }}
              src={shareIcon2}
            />
            <div style={{ width: 7 }} />
            <img
              onClick={() => shareKakaotalk()}
              style={{ width: "10%", height: "10%", cursor: "pointer" }}
              src={shareIcon3}
            />
            <div style={{ width: 7 }} />
            <img
              onClick={() => shareNaver()}
              style={{ width: "10%", height: "10%", cursor: "pointer" }}
              src={shareIcon4}
            />
            <div style={{ width: 7 }} />
            <Link
              to="/result"
              style={{ width: "10%", height: "10%" }}
              onClick={(e) => e.preventDefault()}
            >
              <CopyToClipboard
                text="https://place.scjandrew.net/"
                onCopy={() => alert("링크가 복사되었습니다!")}
              >
                {/*style={{ width: "65%", transform: `translate(25%, 0%)` }}*/}
                <img
                  alt="공유 버튼"
                  style={{ width: "100%", height: "100%", zIndex: "1" }}
                  src={shareIcon5}
                />
              </CopyToClipboard>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Home;
