import React from "react";
import Gaugebar from "../components/Gaugebar";
import Quiz from "../components/Quiz";
import Buttons from "../components/Buttons";
import SubTitle from "./SubTitle";
import btnBack from "../img/btn_back.png";
import btnHome from "../img/btn_home.png";
import { Link } from "react-router-dom";

const QuizPage = (props) => {
  return (
    <div
      style={{
        maxWidth: 410,
        //backgroundImage: "url(" + background + ")",
        height: "100%",
        width: "100%",
        position: "absolute",
        left: "50%",
        transform: `translate(-50%, 0%)`,
      }}
    >
      <div>
        {props.index * 1 === 1 ||
        props.index * 1 === 7 ||
        props.index * 1 === 13 ||
        props.index * 1 === 19 ||
        props.index * 1 === 25 ||
        props.index * 1 === 31 ? (
          <>
            <SubTitle index={props.index} />
          </>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "0 auto",
                width: "76%",
                marginTop: "10px",
              }}
            >
              <Link
                to="/"
                onClick={(e) => {
                  e.preventDefault();
                  props.history.goBack();
                }}
                style={{
                  display: "flex",
                  marginRight: "auto",
                  width: "21%",
                  height: "21%",
                  zIndex: 1,
                }}
              >
                <img
                  alt="뒤로"
                  src={btnBack}
                  style={{ width: "100%", height: "100%" }}
                />
              </Link>
              <a
                href="https://quiz.scjandrew.net/"
                style={{
                  display: "flex",
                  marginLeft: "auto",
                  width: "11.8%",
                  height: "11.8%",
                  zIndex: 1,
                }}
              >
                <img
                  alt="홈으로"
                  src={btnHome}
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              </a>
              {/*<Link
                to="/"
                onClick={(e) => {}}
                style={{
                  display: "flex",
                  marginLeft: "auto",
                  width: "11.8%",
                  height: "11.8%",
                  zIndex: 1,
                }}
              >
                <img
                  alt="홈으로"
                  src={btnHome}
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
                </Link>*/}
            </div>

            <Gaugebar index={props.index} />
            <Quiz index={props.index} />
            <Buttons index={props.index} />
          </>
        )}
      </div>
      {/*<div
        style={{
          backgroundImage: "url(" + background + ")",
          height: "100%",
          width: "100%",
          fontFamily: "Noto Sans CJK KR",
          fontWeight: "450",
          position: "absolute",
          left: "50%",
          top: "100%",
          transform: `translate(-50%, -100%)`,
          zIndex: -2,
        }}
      ></div>*/}
    </div>
  );
};
export default QuizPage;
