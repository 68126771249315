import React from "react";
import { Link, useLocation } from "react-router-dom";
import textNum1 from "../img/text_num_1.png";
import textNum2 from "../img/text_num_2.png";
import textNum3 from "../img/text_num_3.png";
import textNum4 from "../img/text_num_4.png";
import Media from "react-media";

const MEDIA_QUERIES = {
  pc: "(min-width: 11850px)",
  mobile: "(max-width: 11849px)",
};

const btnStyle = {
  color: "black",
  background: "white",
  padding: ".780rem .75rem",
  border: "2px solid #004c88",
  borderRadius: "0.25rem",
  fontSize: "1rem",
  lineHeight: 1.5,
  display: "flex",
  margin: "0 auto",
  width: "70%",
  textDecoration: "none",
};

function Buttons(props) {
  /* location을 사용해 사용자의 답(선택한 답들이 누적된 답)을 받아옴 */
  const location = useLocation();
  /* 다음 가야할 pathname 만들기 */
  let next = props.index * 1 + 1;
  next = "/quiz/" + next;
  if (props.index * 1 === 36) {
    next = "/loading";
  }

  let a = "",
    b = "",
    c = "",
    d = "";
  //역사

  if (props.index * 1 === 2) {
    a = "덕흥 대원군";
    b = "흥선 대원군";
    c = "전계 대원군";
    d = "정원 대원군";
  } else if (props.index * 1 === 3) {
    a = "당나라";
    b = "몽고 ";
    c = "일본 ";
    d = "청나라";
  } else if (props.index * 1 === 4) {
    a = "베델";
    b = "하멜";
    c = "알렌";
    d = "걸리버";
  } else if (props.index * 1 === 5) {
    a = "콜레라";
    b = "말라리아";
    c = "페스트";
    d = "장티푸스";
  } else if (props.index * 1 === 6) {
    a = "태조 이성계";
    b = "세종대왕";
    c = "연산군";
    d = "공민왕";
  }
  //문학
  else if (props.index * 1 === 8) {
    a = "김억";
    b = "김소월";
    c = "김해경";
    d = "김동인";
  } else if (props.index * 1 === 9) {
    a = "케케한";
    b = "쾌쾌한";
    c = "쾨쾨한";
    d = "퀘퀘한";
  } else if (props.index * 1 === 10) {
    a = "무릎 아래";
    b = "손 아래";
    c = "발 아래";
    d = "어깨 아래";
  } else if (props.index * 1 === 11) {
    a = "선장";
    b = "의사";
    c = "군인";
    d = "선생님";
  } else if (props.index * 1 === 12) {
    a = "삼국지";
    b = "서유기";
    c = "초한지";
    d = "열국기";
  }
  //예술
  else if (props.index * 1 === 14) {
    a = "클라리넷";
    b = "오보에";
    c = "대금";
    d = "트럼펫";
  } else if (props.index * 1 === 15) {
    a = "베토벤";
    b = "슈베르트";
    c = "쇼팽";
    d = "슈만";
  } else if (props.index * 1 === 16) {
    a = "종묘";
    b = "석굴암";
    c = "거북선";
    d = "팔만대장경";
  } else if (props.index * 1 === 17) {
    a = "소프라노";
    b = "테너";
    c = "베이스";
    d = "메조 소프란노";
  } else if (props.index * 1 === 18) {
    a = "팬터마임";
    b = "인형극";
    c = "뮤지컬";
    d = "1인극";
  }
  //과학
  else if (props.index * 1 === 20) {
    a = "멜라토닌";
    b = "인슐린";
    c = "펩티드호르몬";
    d = "옥시토신";
  } else if (props.index * 1 === 21) {
    a = "심장";
    b = "허파";
    c = "콩팥";
    d = "관절";
  } else if (props.index * 1 === 22) {
    a = "대류권";
    b = "성층권";
    c = "중간권";
    d = "외기권";
  } else if (props.index * 1 === 23) {
    a = "갈릴레이";
    b = "코페르니쿠스";
    c = "스티븐호킹";
    d = "허블";
  } else if (props.index * 1 === 24) {
    a = "약 100도";
    b = "약 1,000도";
    c = "약 1,500도";
    d = "약 6,000도";
  }
  //사회
  else if (props.index * 1 === 26) {
    a = "Korea Filter";
    b = "King Filter";
    c = "Kinetic Filter";
    d = "Keep Filter";
  } else if (props.index * 1 === 27) {
    a = "고양이(CAT)";
    b = "소(COW)";
    c = "닭(CHICKEN)";
    d = "침팬지(CHIMPANZEE)";
  } else if (props.index * 1 === 28) {
    a = "냄비";
    b = "헬멧";
    c = "우산";
    d = "가방";
  } else if (props.index * 1 === 29) {
    a = "쌀";
    b = "옥수수";
    c = "콩";
    d = "커피";
  } else if (props.index * 1 === 30) {
    a = "at sign(@)";
    b = "해시태그(#)";
    c = "세미콜론(;)";
    d = "슬래시(/)";
  }
  //종교
  else if (props.index * 1 === 32) {
    a = "아킬레스";
    b = "아폴로";
    c = "헤라클레스";
    d = "트로이";
  } else if (props.index * 1 === 33) {
    a = "애니미즘";
    b = "토테미즘";
    c = "샤머니즘";
    d = "액토플라즘";
  } else if (props.index * 1 === 34) {
    a = "태양신";
    b = "우주신";
    c = "시바신";
    d = "비슈누";
  } else if (props.index * 1 === 35) {
    a = "연꽃";
    b = "우담바라";
    c = "바리데기";
    d = "기린";
  } else if (props.index * 1 === 36) {
    a = "예수의 탄생";
    b = "로마의 황금기";
    c = "피라미드 완공시기";
    d = "삼국시대 시작시기";
  }

  return (
    <div style={{ transform: `translate(0%, -12%)` }}>
      <Media queries={MEDIA_QUERIES}>
        {(m) => {
          return (
            <>
              {m.pc && (
                <>
                  <Link
                    to={{
                      pathname: next,
                      state: { answer: location.state.answer + "1/" },
                    }}
                    style={btnStyle}
                  >
                    <img
                      alt="답1"
                      src={textNum1}
                      style={{
                        width: "7%",
                        height: "7%",
                      }}
                    />
                    <div
                      style={{
                        width: "7%",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        fontFamily: "Noto Sans CJK KR",
                        fontSize: 30,
                        fontWeight: 400,
                      }}
                    >
                      {a}
                    </div>
                  </Link>
                  <div style={{ flexBasis: "100%", height: 10 }} />
                  <Link
                    to={{
                      pathname: next,
                      state: { answer: location.state.answer + "2/" },
                    }}
                    style={btnStyle}
                  >
                    <img
                      alt="답2"
                      src={textNum2}
                      style={{
                        width: "7%",
                        height: "7%",
                      }}
                    />
                    <div
                      style={{
                        width: "7%",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        transform: `translate(0%, 0%)`,
                        fontFamily: "Noto Sans CJK KR",
                        fontSize: 30,
                        fontWeight: 400,
                      }}
                    >
                      {b}
                    </div>
                  </Link>
                  <div style={{ flexBasis: "100%", height: 10 }} />
                  <Link
                    to={{
                      pathname: next,
                      state: { answer: location.state.answer + "3/" },
                    }}
                    style={btnStyle}
                  >
                    <img
                      alt="답3"
                      src={textNum3}
                      style={{
                        width: "7%",
                        height: "7%",
                      }}
                    />
                    <div
                      style={{
                        width: "7%",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        transform: `translate(0%, 0%)`,
                        fontFamily: "Noto Sans CJK KR",
                        fontSize: 30,
                        fontWeight: 400,
                      }}
                    >
                      {c}
                    </div>
                  </Link>
                  <div style={{ flexBasis: "100%", height: 10 }} />
                  <Link
                    to={{
                      pathname: next,
                      state: { answer: location.state.answer + "4/" },
                    }}
                    style={btnStyle}
                  >
                    <img
                      alt="답4"
                      src={textNum4}
                      style={{
                        width: "7%",
                        height: "7%",
                      }}
                    />
                    <div
                      style={{
                        width: "7%",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        transform: `translate(0%, 0%)`,
                        fontFamily: "Noto Sans CJK KR",
                        fontSize: 30,
                        fontWeight: 400,
                      }}
                    >
                      {d}
                    </div>
                  </Link>
                </>
              )}
              {m.mobile && (
                <>
                  <Link
                    to={{
                      pathname: next,
                      state: { answer: location.state.answer + "1/" },
                    }}
                    style={btnStyle}
                  >
                    <img
                      alt="답1"
                      src={textNum1}
                      style={{
                        width: "11%",
                      }}
                    />
                    <div
                      style={{
                        width: "7%",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        fontFamily: "Noto Sans CJK KR",
                        fontSize: 17,
                        fontWeight: 500,
                      }}
                    >
                      {a}
                    </div>
                  </Link>
                  <div style={{ flexBasis: "100%", height: 10 }} />
                  <Link
                    to={{
                      pathname: next,
                      state: { answer: location.state.answer + "2/" },
                    }}
                    style={btnStyle}
                  >
                    <img
                      alt="답2"
                      src={textNum2}
                      style={{
                        width: "11%",
                      }}
                    />
                    <div
                      style={{
                        width: "7%",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        transform: `translate(0%, 0%)`,
                        fontFamily: "Noto Sans CJK KR",
                        fontSize: 17,
                        fontWeight: 500,
                      }}
                    >
                      {b}
                    </div>
                  </Link>
                  <div style={{ flexBasis: "100%", height: 10 }} />
                  <Link
                    to={{
                      pathname: next,
                      state: { answer: location.state.answer + "3/" },
                    }}
                    style={btnStyle}
                  >
                    <img
                      alt="답3"
                      src={textNum3}
                      style={{
                        width: "11%",
                      }}
                    />
                    <div
                      style={{
                        width: "7%",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        transform: `translate(0%, 0%)`,
                        fontFamily: "Noto Sans CJK KR",
                        fontSize: 17,
                        fontWeight: 500,
                      }}
                    >
                      {c}
                    </div>
                  </Link>
                  <div
                    style={{
                      flexBasis: "100%",
                      height: 10,
                    }}
                  />
                  <Link
                    to={{
                      pathname: next,
                      state: { answer: location.state.answer + "4/" },
                    }}
                    style={btnStyle}
                  >
                    <img
                      alt="답4"
                      src={textNum4}
                      style={{
                        width: "11%",
                      }}
                    />
                    <div
                      style={{
                        width: "7%",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        transform: `translate(0%, 0%)`,
                        fontFamily: "Noto Sans CJK KR",
                        fontSize: 17,
                        fontWeight: 500,
                      }}
                    >
                      {d}
                    </div>
                  </Link>
                  <div
                    style={{
                      flexBasis: "100%",
                      height: 10,
                    }}
                  />
                </>
              )}
            </>
          );
        }}
      </Media>
    </div>
  );
}

export default Buttons;
