import React from "react";
import { Link, useLocation } from "react-router-dom";
import characterArt from "../gif/character_art.gif";
import characterHistory from "../gif/character_history.gif";
import characteLiterature from "../gif/character_literature.gif";
import characterReligion from "../gif/character_religion.gif";
import characterScience from "../gif/character_science.gif";
import characterSocial from "../gif/character_social.gif";
import nextbtn from "../img/nextbtn.png";
import bg2 from "../img/bg_pattern2.jpg";
import bg3 from "../img/bg_pattern3.jpg";
import bg4 from "../img/bg_pattern4.jpg";
import bg5 from "../img/bg_pattern5.jpg";
import bg6 from "../img/bg_pattern6.jpg";
import bg7 from "../img/bg_pattern7.jpg";
const SubTitle = (props) => {
  /* location을 사용해 사용자의 답(선택한 답들이 누적된 답)을 받아옴 */
  const location = useLocation();

  /* 다음 가야할 pathname 만들기 */
  let next = props.index * 1 + 1;
  next = "/quiz/" + next;

  let background, sub1, sub21, sub22, character;
  if (props.index * 1 === 1) {
    background = bg2;
    sub1 = "역사";
    sub21 = "내가 왕이 될 상인가~~~";
    sub22 = "장원급제 뺨치는 문제들 시작해보자!";
    character = characterHistory;
  } else if (props.index * 1 === 7) {
    background = bg6;
    sub1 = "문학";
    sub21 = "1번이냐~ 2번이냐~";
    sub22 = "그것이 문제로다.";
    character = characteLiterature;
  } else if (props.index * 1 === 13) {
    background = bg3;
    sub1 = "예술";
    sub21 = "다음은 예술 문제를 풀어볼거에요.";
    sub22 = "참 쉽죠?";
    character = characterArt;
  } else if (props.index * 1 === 19) {
    background = bg5;
    sub1 = "과학";
    sub21 = "100점은 1퍼센트의 지식과";
    sub22 = "90퍼센트의 찍기(???) 감이다!";
    character = characterScience;
  } else if (props.index * 1 === 25) {
    background = bg7;
    sub1 = "사회";
    sub21 = "내가 왕이 될 상인가~~~";
    sub22 = "장원급제 뺨치는 문제들 시작해보자!";
    character = characterSocial;
  } else if (props.index * 1 === 31) {
    background = bg4;
    sub1 = "종교";
    sub21 = "주님 오늘도 상식적인 천재가 되는 걸";
    sub22 = "허락해주세요. 루루팡! 루루피! 루루~얏!";
    character = characterReligion;
  }

  return (
    <>
      <div
        style={{
          maxWidth: 410,
          backgroundImage: "url(" + background + ")",
          height: "100%",
          width: "100%",
          position: "absolute",
          left: "50%",
          transform: `translate(-50%, 0%)`,
        }}
      >
        <br />
        <div style={{ flexBasis: "100%", height: 70 }} />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "0 auto",
            fontSize: 50,
            color: "#0064b2",
            fontWeight: 800,
          }}
        >
          {sub1}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "0 auto",
            flexWrap: "wrap",
            fontFamily: "Noto Sans CJK KR",
            fontWeight: 400,
          }}
        >
          <div style={{ fontSize: 16 }}>{sub21}</div>
          <div style={{ flexBasis: "100%", height: 0 }}></div>
          <div style={{ fontSize: 16 }}>{sub22}</div>
          <br />
          <br />
        </div>
        <img
          alt="캐릭터"
          style={{
            width: "40%",
            display: "flex",
            justifyContent: "center",
            margin: "0 auto",
          }}
          src={character}
        />
        <br />
        <Link to={{ pathname: next, state: { answer: location.state.answer } }}>
          <img
            alt="다음 문제로 넘어가기"
            style={{
              width: "35%",
              display: "flex",
              justifyContent: "center",
              margin: "0 auto",
            }}
            src={nextbtn}
          />
        </Link>
      </div>
      <div
        style={{
          backgroundImage: "url(" + background + ")",
          height: "100%",
          width: "100%",
          fontFamily: "Noto Sans CJK KR",
          fontWeight: "450",
          position: "absolute",
          left: "50%",
          top: "100%",
          transform: `translate(-50%, -100%)`,
          zIndex: -1,
        }}
      ></div>
    </>
  );
};
export default SubTitle;
