import React from "react";
import { Link } from "react-router-dom";
import { Bar } from "react-chartjs-2";
import Media from "react-media";
import html2canvas from "html2canvas";
import { CopyToClipboard } from "react-copy-to-clipboard";

import mainTitle from "../img/main_title.png";
import resultTitle from "../img/result_title.png";
import background from "../img/bg_pattern1.jpg";
import subject1 from "../img/subject_1.png";
import subject2 from "../img/subject_2.png";
import subject3 from "../img/subject_3.png";
import subject4 from "../img/subject_4.png";
import subject5 from "../img/subject_5.png";
import subject6 from "../img/subject_6.png";

import res1 from "../img/1.png";
import res2 from "../img/2.png";
import res3 from "../img/3.png";
import res4 from "../img/4.png";
import res5 from "../img/5.png";

import shareIcon1 from "../img/shareicon_1.png";
import shareIcon2 from "../img/shareicon_2.png";
import shareIcon3 from "../img/shareicon_3.png";
import shareIcon4 from "../img/shareicon_4.png";
import shareIcon5 from "../img/shareicon_5.png";

import resultFaceIcon from "../img/result_faceicon2.png";
import more from "../img/btn_other contents_go_mobile.ver.png";
import resultBtn1 from "../img/result_btn1.png";
import resultBtn2 from "../img/result_btn2.png";

const btnStyle = {
  color: "black",
  background: "white",
  padding: "1.75rem .75rem",
  border: "3px solid #004c88",
  borderRadius: "0.75rem",
  fontSize: "1rem",
  lineHeight: 1.5,
  display: "flex",
  margin: "0 auto",
  width: "80%",
  height: "30%",
  transform: `translate(0%, -8%)`,
  flexWrap: "wrap",
  fontFamily: "Noto Sans CJK KR",
  fontWeight: 200,
  position: "relative",
  zIndex: -1,
};
const btnStyle2 = {
  color: "black",
  background: "white",
  padding: "1.55rem .75rem",
  border: "3px solid #004c88",
  borderRadius: "0.75rem",
  fontSize: "1rem",
  lineHeight: 1.5,
  display: "flex",
  margin: "0 auto",
  width: "80%",
  height: "40%",
  transform: `translate(0%, -100%)`,
  flexWrap: "wrap",
  fontFamily: "Noto Sans CJK KR",
  fontWeight: 200,
  position: "relative",
  zIndex: -1,
};

const txtStyle1 = {
  width: "100%",
  display: "flex",
  justifyContent: "center",
  textIndent: "10px",
};
const MEDIA_QUERIES = {
  pc: "(min-width: 11730px)",
  mobile: "(max-width: 11729px)",
};

//window.Kakao.init("39ff4b8354a51b8c30a8696c254ad4cc");

class Result extends React.Component {
  constructor(props) {
    super(props);
    let ans = props.location.state.answer.split("/");
    let cor = [
      2, 2, 2, 3, 1, 2, 3, 1, 2, 2, 4, 2, 3, 1, 1, 2, 3, 1, 1, 4, 1, 2, 3, 1, 2,
      1, 2, 1, 2, 1,
    ];
    let d = [0, 0, 0, 0, 0, 0];
    for (let i = 0; i < ans.length; i++) {
      if (ans[i] * 1 === cor[i] * 1) {
        if (i >= 0 && i < 5) d[0] += 20;
        //역사
        else if (i >= 5 && i < 10) d[1] += 20;
        //문학
        else if (i >= 10 && i < 15) d[2] += 20;
        //예술
        else if (i >= 15 && i < 20) d[3] += 20;
        //과학
        else if (i >= 20 && i < 25) d[4] += 20;
        //사회
        else if (i >= 25 && i < 30) d[5] += 20; //종교
      }
    }

    let total = d[0] + d[1] + d[2] + d[3] + d[4] + d[5];
    const data = {
      labels: ["역사", "문학", "예술", "과학", "사회", "종교"],
      datasets: [
        {
          type: "bar",
          barPercentage: 100,
          barThickness: 60,
          maxBarThickness: 30,
          minBarLength: 0,
          data: d,
          backgroundColor: [
            d[0] <= 40 ? "#c80000" : "#e3e3e3",
            d[1] <= 40 ? "#c80000" : "#e3e3e3",
            d[2] <= 40 ? "#c80000" : "#e3e3e3",
            d[3] <= 40 ? "#c80000" : "#e3e3e3",
            d[4] <= 40 ? "#c80000" : "#e3e3e3",
            d[5] <= 40 ? "#c80000" : "#e3e3e3",
          ],
          borderColor: [
            d[0] <= 40 ? "#c80000" : "#e3e3e3",
            d[1] <= 40 ? "#c80000" : "#e3e3e3",
            d[2] <= 40 ? "#c80000" : "#e3e3e3",
            d[3] <= 40 ? "#c80000" : "#e3e3e3",
            d[4] <= 40 ? "#c80000" : "#e3e3e3",
            d[5] <= 40 ? "#c80000" : "#e3e3e3",
          ],
          borderWidth: 1,
          borderRadius: 100,
        },
      ],
    };

    this.state = {
      cor: cor,
      d: d,
      forCapture: "none",
      total: total,
      data: data,
      ans: ans,
    };
  }

  getScreenshotHandler = () => {
    //document.querySelector("#capture").style.set
    html2canvas(document.querySelector("#capture")).then((canvas) => {
      //console.log(_trans);
      let Img = canvas.toDataURL("image/png");
      let link = document.createElement("a");
      link.download = "result.png";
      link.href = Img;

      document.body.appendChild(link);
      link.click();
      this.setState({ forCapture: "translate(0%, 0%)" });
    });
  };

  getSubject = () => {
    let arr = [];

    const d_label = ["역사", "문학", "예술", "과학", "사회", "종교"];

    for (let i = 0; i < this.state.d.length; i++) {
      if (this.state.d[i] <= 40) {
        arr.push(i);
      }
    }
    return (
      <div
        style={{
          maxWidth: 410,
          display: "flex",
          flexDirection: "row",
          margin: "0 auto",
          justifyContent: "space-around",
          width: "95%",
          flexWrap: "wrap",
          transform: `translate(0%, 0%)`,
        }}
      >
        {arr.length === 0 ? (
          <div
            style={{
              fontSize: 30,
              fontFamily: "Noto Sans CJK KR",
              fontWeight: 600,
            }}
          >
            Pass
          </div>
        ) : (
          arr.map((i) => {
            return (
              <Media key={i} queries={MEDIA_QUERIES}>
                {(m) => {
                  return (
                    <>
                      {m.pc && (
                        <div
                          style={{
                            fontSize:
                              arr.length >= 6 ? 25 : arr.length >= 4 ? 30 : 30,
                            fontFamily: "Noto Sans CJK KR",
                            fontWeight: 600,
                            color:
                              i === 0
                                ? "#00b4ab"
                                : i === 1
                                ? "#e6c226"
                                : i === 2
                                ? "#fe7c00"
                                : i === 3
                                ? "#ff5e97"
                                : i === 4
                                ? "#0269a4"
                                : "#011b56",
                          }}
                        >
                          {d_label[i]}
                        </div>
                      )}
                      {m.mobile && (
                        <div
                          style={{
                            fontSize:
                              arr.length >= 6 ? 22 : arr.length >= 5 ? 26 : 30,
                            fontFamily: "Noto Sans CJK KR",
                            fontWeight: 600,
                            color:
                              i === 0
                                ? "#00b4ab"
                                : i === 1
                                ? "#e6c226"
                                : i === 2
                                ? "#fe7c00"
                                : i === 3
                                ? "#ff5e97"
                                : i === 4
                                ? "#0269a4"
                                : "#011b56",
                          }}
                        >
                          {d_label[i]}
                        </div>
                      )}
                    </>
                  );
                }}
              </Media>
            );
          })
        )}
      </div>
    );
  };

  shareFacebook = () => {
    window.open(
      "https://www.facebook.com/sharer/sharer.php?u=https://quiz.scjandrew.net/"
    );
  };
  shareTwitter = () => {
    window.open(
      "https://www.twitter.com/intent/tweet?&url=https://quiz.scjandrew.net/"
    );
  };
  shareKakaotalk = () => {
    window.Kakao.Link.sendDefault({
      objectType: "feed",
      content: {
        title: "알잘딱깔센 상식테스트",
        description: "",
        imageUrl: mainTitle,
        link: {
          mobileWebUrl: "https://quiz.scjandrew.net/",
          androidExecParams: "",
        },
      },
      buttons: [
        {
          title: "웹으로 이동",
          link: {
            mobileWebUrl: "https://quiz.scjandrew.net/",
          },
        },
      ],
    });
  };
  shareNaver = () => {
    window.open(
      "https://share.naver.com/web/shareView.nhn?url=https://quiz.scjandrew.net/&title=알잘딱깔센상식테스트"
    );
  };

  render() {
    const { d, forCapture, total, data } = this.state;
    return (
      <div
        id="capture"
        style={{
          position: "relative",
          zIndex: 1,
          maxWidth: 410,
          height: "100%",
          width: "100%",
          margin: "0 auto",
          transform: forCapture,
          backgroundImage: "url(" + background + ")",
        }}
      >
        <br />
        <img
          alt="결과"
          src={resultTitle}
          style={{ width: "85%", display: "flex", margin: "0 auto", zIndex: 1 }}
        />
        <div style={btnStyle}>
          {/*<div style={{ flexBasis: "100%", height: 0 }} />*/}
          <Media queries={MEDIA_QUERIES}>
            {(m) => {
              return (
                <>
                  {m.pc && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          margin: "0 auto",
                          fontFamily: "Noto Sans CJK KR",
                          fontSize: 17,
                          fontWeight: 400,
                          zIndex: -1,
                        }}
                      >
                        {total <= 30 * 6
                          ? `이런! 눈 감고 치신거 아니죠?`
                          : total <= 50 * 6
                          ? `괜찮아요~`
                          : total <= 70 * 6
                          ? `당신은 의사소통 가능한`
                          : total <= 90 * 6
                          ? `나는야 척척박사! 무엇이든 물어보세요`
                          : `상위1%의 상식수준을 갖추셨네요!`}
                      </div>
                      <div style={{ flexBasis: "100%", height: 10 }} />
                      <div
                        style={{
                          display: "flex",
                          margin: "0 auto",
                          fontFamily: "Noto Sans CJK KR",
                          fontSize: 17,
                          fontWeight: 400,
                          zIndex: -1,
                        }}
                      >
                        {total <= 30 * 6
                          ? ``
                          : total <= 50 * 6
                          ? `배워나가야 할 것들이 많네요!`
                          : total <= 70 * 6
                          ? `탁월한 상식인!`
                          : total <= 90 * 6
                          ? `단, 모르는 것도 있습니다^^`
                          : `당신은 걸어다니는 백과사전이신가요?`}
                      </div>
                    </>
                  )}
                  {m.mobile && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          margin: "0 auto",
                        }}
                      >
                        <div
                          style={{
                            fontFamily: "Noto Sans CJK KR",
                            fontSize: 80,
                            color: "#c80000",
                            paddingBottom: 5,
                            paddingTop: 5,
                            fontWeight: 800,
                            zIndex: -1,
                          }}
                        >
                          {Math.ceil(total / 6)}
                        </div>
                        <div
                          style={{
                            fontFamily: "Noto Sans CJK KR",
                            fontSize: 25,
                            color: "#c80000",
                            paddingBottom: 5,
                            paddingTop: 65,
                            fontWeight: 800,
                            zIndex: -1,
                          }}
                        >
                          점
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          margin: "0 auto",
                          fontFamily: "Noto Sans CJK KR",
                          paddingBottom: 25,
                          fontWeight: 400,
                          zIndex: -1,
                        }}
                      >
                        {total <= 30 * 6 ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              style={{ width: "65%" }}
                              src={res1}
                              alt="결과이미지"
                            />
                          </div>
                        ) : total <= 50 * 6 ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              style={{ width: "65%" }}
                              src={res2}
                              alt="결과이미지"
                            />
                          </div>
                        ) : total <= 70 * 6 ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              style={{ width: "65%" }}
                              src={res3}
                              alt="결과이미지"
                            />
                          </div>
                        ) : total <= 90 * 6 ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              style={{ width: "75%" }}
                              src={res4}
                              alt="결과이미지"
                            />
                          </div>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              style={{ width: "75%" }}
                              src={res5}
                              alt="결과이미지"
                            />
                          </div>
                        )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          margin: "0 auto",
                          fontFamily: "Noto Sans CJK KR",
                          fontSize: 15,
                          fontWeight: 500,
                          zIndex: -1,
                        }}
                      >
                        {total <= 30 * 6
                          ? `이런! 눈 감고 치신거 아니죠?`
                          : total <= 50 * 6
                          ? `괜찮아요~`
                          : total <= 70 * 6
                          ? `당신은 의사소통 가능한`
                          : total <= 90 * 6
                          ? `나는야 척척박사! 무엇이든 물어보세요`
                          : `상위1%의 상식수준을 갖추셨네요!`}
                      </div>
                      <div style={{ flexBasis: "100%", height: 5 }} />
                      <div
                        style={{
                          display: "flex",
                          margin: "0 auto",
                          fontFamily: "Noto Sans CJK KR",
                          fontSize: 15,
                          fontWeight: 500,
                          zIndex: -1,
                        }}
                      >
                        {total <= 30 * 6
                          ? ``
                          : total <= 50 * 6
                          ? `배워나가야 할 것들이 많네요!`
                          : total <= 70 * 6
                          ? `탁월한 상식인!`
                          : total <= 90 * 6
                          ? `단, 모르는 것도 있습니다^^`
                          : `당신은 걸어다니는 백과사전이신가요?`}
                      </div>
                    </>
                  )}
                </>
              );
            }}
          </Media>

          <div style={{ flexBasis: "100%", height: 35 }} />
          <div
            style={{
              flexBasis: "85%",
              height: 1,
              margin: "0 auto",
              background: "#d6d6d6",
            }}
          />
          <div
            style={{
              height: "8%",
              width: "100%",
              margin: "0 auto",
              transform: `translate(-3%, 5%)`,
            }}
          >
            <br />
            <Bar
              data={data}
              options={{
                plugins: {
                  legend: false, // label 보이기 여부
                },
                scales: {
                  y: {
                    min: 0,
                    max: 100,
                    stepSize: 20,
                  },
                },
              }}
            />
            <Media queries={MEDIA_QUERIES}>
              {(m) => {
                return (
                  <>
                    {m.pc && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-around",
                          width: "96%",
                          flexWrap: "wrap",
                          transform: `translate(5%, -30%)`,
                        }}
                      >
                        <div style={{ width: "15%" }}>
                          <img
                            src={subject1}
                            style={{ width: "100%" }}
                            alt="역사"
                          />
                        </div>
                        <div style={{ width: "15%" }}>
                          <img
                            src={subject2}
                            style={{ width: "100%" }}
                            alt="문학"
                          />
                        </div>
                        <div style={{ width: "15%" }}>
                          <img
                            src={subject4}
                            style={{ width: "100%" }}
                            alt="예술"
                          />
                        </div>
                        <div style={{ width: "15%" }}>
                          <img
                            src={subject5}
                            style={{ width: "100%" }}
                            alt="과학"
                          />
                        </div>
                        <div style={{ width: "15%" }}>
                          <img
                            src={subject6}
                            style={{ width: "100%" }}
                            alt="사회"
                          />
                        </div>
                        <div style={{ width: "15%" }}>
                          <img
                            src={subject3}
                            style={{ width: "100%" }}
                            alt="종교"
                          />
                        </div>
                      </div>
                    )}
                    {m.mobile && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-around",
                          width: "90%",
                          flexWrap: "wrap",
                          transform: `translate(12%, -55%)`,
                        }}
                      >
                        <div style={{ width: "15%" }}>
                          <img
                            src={subject1}
                            style={{ width: "100%" }}
                            alt="역사"
                          />
                        </div>
                        <div style={{ width: "15%" }}>
                          <img
                            src={subject2}
                            style={{ width: "100%" }}
                            alt="문학"
                          />
                        </div>
                        <div style={{ width: "15%" }}>
                          <img
                            src={subject4}
                            style={{ width: "100%" }}
                            alt="예술"
                          />
                        </div>
                        <div style={{ width: "15%" }}>
                          <img
                            src={subject5}
                            style={{ width: "100%" }}
                            alt="과학"
                          />
                        </div>
                        <div style={{ width: "15%" }}>
                          <img
                            src={subject6}
                            style={{ width: "100%" }}
                            alt="사회"
                          />
                        </div>
                        <div style={{ width: "15%" }}>
                          <img
                            src={subject3}
                            style={{ width: "100%" }}
                            alt="종교"
                          />
                        </div>
                      </div>
                    )}
                  </>
                );
              }}
            </Media>
          </div>
        </div>
        <br />
        <br />
        <Media queries={MEDIA_QUERIES}>
          {(m) => {
            return (
              <>
                {m.pc && (
                  <>
                    <img
                      alt="이 분야는 더 공부 해야해요"
                      style={{
                        width: "50%",
                        display: "flex",
                        margin: "0 auto",
                        transform: `translate(0%,-50%)`,
                        zIndex: 1,
                      }}
                      src={resultFaceIcon}
                    />
                    <div style={btnStyle2}>{this.getSubject()}</div>
                    {/*<Link to={{pathname:'/', state:{answer: ""}}}><button>Home</button></Link>*/}
                    <br />
                    <br />
                    <br />
                  </>
                )}
                {m.mobile && (
                  <>
                    <img
                      alt="이 분야는 더 공부 해야해요"
                      style={{
                        width: "70%",
                        display: "flex",
                        margin: "0 auto",
                        transform: `translate(0%,-130%)`,
                        zIndex: 1,
                      }}
                      src={resultFaceIcon}
                    />
                    <div style={btnStyle2}>{this.getSubject()}</div>

                    {/*<Link to={{pathname:'/', state:{answer: ""}}}><button>Home</button></Link>*/}
                  </>
                )}
              </>
            );
          }}
        </Media>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "0 auto",
            fontFamily: "Noto Sans CJK KR",
            fontSize: 15,
            fontWeight: 500,
            width: "87%",
          }}
        >
          {d[5] <= 40 ? ( //종교
            <div style={{ transform: `translate(0%, -19%)` }}>
              <div style={txtStyle1}>
                {" "}
                인류가 존재하는 한 종교는 분리 될 수 없으며 이것은 곧 인간의
                과거와 현재 미래의 불확실한 삶에 대한 두려움의 의지처로서 본능적
                활동 이라 할수 있어요.
              </div>
              <div style={txtStyle1}>
                <div
                  style={{
                    fontWeight: 1400,
                    color: "#0064b2",
                    display: "contents",
                  }}
                >
                  종교
                </div>
                는 인간의 궁극적 행복을 추구해가는 근본 이치를 제시하는데요,
                종교의 차이에 따라 그 방편의 차이와, 개인의 차이에(性의 格) 따라
                그 제시를 얼마나 심오하게 받아들여 깨우치느냐 하는 차이는
                있겠지만 그것은 그렇게 큰 것은 아닌 것으로서 얼마나 지극히
                노력하는냐 하는 정도의 차이 일수도 있어요.
              </div>
              <div style={txtStyle1}>
                종교적 삶은 현재의 삶과 미래(사후)의 삶을 보장받을수 있는 유일한
                길임을 많은 聖人이나 경전, 성경에서 이미 제시되어 있기도 하죠.
                그렇기에 어렵더라도 꼭 공부해보는 걸 추천드려요!
              </div>
            </div>
          ) : d.lastIndexOf(Math.min.apply(null, d)) === 0 && d[0] <= 40 ? ( //역사
            <div style={{ transform: `translate(0%, -42%)` }}>
              <div style={txtStyle1}>
                누군가의 성공과 실패에서 배울 것이 있다면 역사를 읽는 것만으로도
                우리에게는 충분한 도움이 되는 것이며, 그것을 자신의
                관점에서해석해 낸다면 또 다른 세상을 바라보는 관점을만들 수도
                있을 거에요!{" "}
                <span
                  style={{
                    fontWeight: 1400,
                    color: "#0064b2",
                    display: "contents",
                  }}
                >
                  역사
                </span>
                는 그냥 흘러간 과거가 아니라 오늘을 살아가는 우리에게 가르침을
                주기 위해서 존재하는 것이니 어렵더라도 꼭 공부해보는 걸
                추천드려요!
              </div>
            </div>
          ) : d.lastIndexOf(Math.min.apply(null, d)) === 1 && d[1] <= 40 ? ( //문학
            <div style={{ transform: `translate(0%, -13%)` }}>
              <div style={txtStyle1}>
                <span
                  style={{
                    fontWeight: 1400,
                    color: "#0064b2",
                    display: "contents",
                  }}
                >
                  문학
                </span>
                은 시대를 같이 흘러오면서 독자와 호흡을 하며 우리의 삶에서
                중요한 역할을 해왔어요. 흔히 문학을 한 시대의 사회/문화의
                나침반이라고 하는 것처럼 문학은 시대를 말해줍니다. 이러한 문학의
                사회적인 기능은 독자 그리고 현대를 살아가는 국민들에게 많은
                시사점을 제시해주는데요. 문학을 통해 민주주의의 정신을 살리고,
                문학을 통해 우리네 삶을 조명하며, 이웃의 아픔을 느낄 수 있는
                것처럼 말이죠.
              </div>
              <div style={txtStyle1}>
                우리가 문학을 접하면서 많은 것을 배울 수 있는 것은{" "}
                <span
                  style={{
                    fontWeight: 1400,
                    color: "#0064b2",
                    display: "contents",
                  }}
                >
                  문학
                </span>
                이 그 사회/문화의 경향을 민감하게 비추는 거울이자, 그
                사회/문화가 나아갈 방향을 제시하는 선도적 역할을 담당하기
                때문이랍니다. 따라서 우리는 문학 활동을 통해 사회/문화적 현상을
                폭넓게 분석하고 비판하고, 또한 우리 사회와 문화의 다양한
                이슈들을 표현할 수 있어야 해요. 이런 활동은 문학 문화의 발전은
                물론, 개인과 공동체의 발전에 밑거름이 될 것이기에 어렵더라도 꼭
                공부해보는 걸 추천드려요!
              </div>
            </div>
          ) : d.lastIndexOf(Math.min.apply(null, d)) === 2 && d[2] <= 40 ? ( //예술
            <div style={{ transform: `translate(0%, -27%)` }}>
              <div style={txtStyle1}>
                우리는 현실을 살아가기 위해 전반적으로 행복의 기준을 물질적인
                풍요로움에 기반을 두고 시작해요. 때문에 과거로부터 현재까지
                살아오면서 생리적 욕구에 대한 집착은{" "}
                <span
                  style={{
                    fontWeight: 1400,
                    color: "#0064b2",
                    display: "contents",
                  }}
                >
                  예술
                </span>
                적 행위에 대해 얼마나 등한시 하고 지내는지 감각하지 못하게 하죠.
              </div>
              <div style={txtStyle1}>
                하지만 반복적이고 일상적인 생활중에 우연히도 마주치는 창작물을
                두고 우리는 그 감성에 공감하여 감동 받을 때가 있답니다. 이는
                우리를 이완시켜주며 한 방향에 집중되어 있는 감각을 보다 넓게
                환기시켜주는 작용을 하죠.{" "}
                <span
                  style={{
                    fontWeight: 1400,
                    color: "#0064b2",
                    display: "contents",
                  }}
                >
                  예술
                </span>
                분야가 어렵지만 꼭 공부해보는 걸 추천드려요!
              </div>
            </div>
          ) : d.lastIndexOf(Math.min.apply(null, d)) === 3 && d[3] <= 40 ? ( //과학
            <div style={{ transform: `translate(0%, -18%)` }}>
              <div style={txtStyle1}>
                <span
                  style={{
                    fontWeight: 1400,
                    color: "#0064b2",
                    display: "contents",
                  }}
                >
                  과학
                </span>
                은 우리가 사는 세계를 더 잘 이해하고 보호할 수 있도록 해줄 뿐만
                아니라 상상력을 펼치고, 새 지평을 향해 나아가게 하는 수단이죠.
                또한 과학을 통해 비즈니스와 경제가 성장하고, 국가가 발전하며
                인류가 진화할 수 있게 되죠.
              </div>
              <div style={txtStyle1}>
                <span
                  style={{
                    fontWeight: 1400,
                    color: "#0064b2",
                    display: "contents",
                  }}
                >
                  과학
                </span>
                이란 '사물의 현상에 대해 보편적 원리 및 법칙을 알아내고 해명하는
                것을 목적으로 하는 지식체계나 학문' 이라고 사전에 정의되어
                있는데요. 우리 주위에서 벌어지고 있는 모든 사물의 현상에 대한
                궁금증을 해결하고, 보편적인 법칙을 통해 앞으로의 일을 예측할 수
                있게 해주는 지식들 중의 하나라고 할 수 있어요.
              </div>
              <div style={txtStyle1}>
                과학을 공부함으로써 우리가 얻게 되는 것 중에 가장 큰 의미가 있는
                것은 바로 '세상을 바라보는 시각'이랍니다. 보다 객관적이고
                합리적으로 생각할 수 있기에 어렵더라도 꼭 공부해보는 걸
                추천드려요!
              </div>
            </div>
          ) : d.lastIndexOf(Math.min.apply(null, d)) === 4 && d[4] <= 40 ? ( //사회
            <div style={{ transform: `translate(0%, -7%)` }}>
              <div style={txtStyle1}>
                <span
                  style={{
                    fontWeight: 1400,
                    color: "#0064b2",
                    display: "contents",
                  }}
                >
                  사회
                </span>
                는 일정한 경계가 설정된 영토에서 종교·가치관·규범·언어·문화 등을
                상호 공유하고 특정한 제도와 조직을 형성하여 질서를 유지하고 성적
                관계를 통하여 성원을 재생산하면서 존속하는 인간집단이라고
                하는데, 정치와 경제, 지리 등이 이에 포함된답니다.
              </div>
              <div style={txtStyle1}>
                <span
                  style={{
                    fontWeight: 1400,
                    color: "#0064b2",
                    display: "contents",
                  }}
                >
                  사회
                </span>
                는 내가 살아가는 공간이자 과거, 현재, 미래의 시간을 공부하는
                과목으로, 내가 어떤 시대에 살고 있고 어떤 곳에서 사는지 알아감을
                통해서 주체적인 삶을 살 수 있게 되죠.
              </div>
              <div style={txtStyle1}></div>
              <div style={txtStyle1}>
                하지만 정치를 담당하는 기관, 사람들이 부당한 정치 행위로 자신의
                이익을 위협하고 있다는 사실 자체를 모르는 사람들이 많아요.
                어떠한 행위가 부당하고 정당한지에 대해 사실판단을 할 수 있다면
                자연스레 옳다 옳지 않다라고 가치판단을 내릴 수 있죠. 이러한 판단
                능력은 자연스레 정치가 내 생활의 일부라는 것을 확인할 수 있게
                해요. 사실판단을 할 수 있다면 자연스레 옳다 옳지 않다라고
                가치판단을 내릴 수 있답니다. 주권자로서 자신의 이익을 어떻게
                지켜나가야 하는지 알게 되고, 이를 바탕으로 자신의 사회적 이익을
                위해 정치에 참여하는 자세도 기를 수 있어요.
              </div>
              <div style={txtStyle1}>
                또한 경제 활동에서 노동자 근로자의 권리에 대한 상식을 바탕으로
                자신의 경제적 권리를 지킬 수 있답니다. 경제 현상을 상식선에서
                분석하고 여러 경제 정책들이 자신의 경제적 이익에 어떠한 영향을
                미칠 것인지 분석하고 대응할 수 있게 되죠.
              </div>
              <div style={txtStyle1}>
                조직의 운영 원리와 사회 계층 현상, 개인과 개인 사이에 발생하는
                갈등에 대한 상식을 내면화 한다면 갈등을 최소화할 수 있어요.
                사회적 쟁점의 합리적 의사결정 과정의 작동 원리를 공동체 구성원
                모두가 내면화했다면 사회 갈등은 그만큼 더 줄어들지 않을까요?
              </div>
              <div style={txtStyle1}>
                사회과-시민과- 학습 내용을 충실히 내면화한 사람들로 가득찬
                사회를 꿈꿔봅니다. 민주 시민의식이 높은 사람들이 많을수록 그
                사회는 보다 ‘나은’ 방향으로 성장해 나갈 수 있으니까요~
              </div>
              <div style={txtStyle1}>
                몇몇 정권을 잡은 사람들이 나서거나, 유명인들의 캠페인으로 이
                사회-공동체가 달라지는 것이 아니라 그 사회-공동체 구성원들의
                민주 시민 의식이 높아지는 것이 그 답이 된다는 걸 기억해주세요.
                그리고 그러한 민주 시민으로 성장하도록 돕는 과목이 바로
                사회과목이기에 어렵더라도 꼭 공부해보는 걸 추천드려요!
              </div>
            </div>
          ) : d.lastIndexOf(Math.min.apply(null, d)) === 5 && d[5] <= 40 ? ( //종교
            <div style={{ transform: `translate(0%, -19%)` }}>
              <div style={txtStyle1}>
                {" "}
                인류가 존재하는 한 종교는 분리 될 수 없으며 이것은 곧 인간의
                과거와 현재 미래의 불확실한 삶에 대한 두려움의 의지처로서 본능적
                활동 이라 할수 있어요.
              </div>
              <div style={txtStyle1}>
                <div
                  style={{
                    fontWeight: 1400,
                    color: "#0064b2",
                    display: "contents",
                  }}
                >
                  종교
                </div>
                는 인간의 궁극적 행복을 추구해가는 근본 이치를 제시하는데요,
                종교의 차이에 따라 그 방편의 차이와, 개인의 차이에(性의 格) 따라
                그 제시를 얼마나 심오하게 받아들여 깨우치느냐 하는 차이는
                있겠지만 그것은 그렇게 큰 것은 아닌 것으로서 얼마나 지극히
                노력하는냐 하는 정도의 차이 일수도 있어요.
              </div>
              <div style={txtStyle1}>
                종교적 삶은 현재의 삶과 미래(사후)의 삶을 보장받을수 있는 유일한
                길임을 많은 聖人이나 경전, 성경에서 이미 제시되어 있기도 하죠.
                그렇기에 어렵더라도 꼭 공부해보는 걸 추천드려요!
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <a
          href="https://www.wordsquare.org/square-crossroads/assessments"
          style={{
            width: "70%",
            display: "flex",
            margin: "0 auto",
            transform: `translate(0%,-30%)`,
            zIndex: 1,
          }}
        >
          <img alt="더 많은 컨텐츠 보기" src={more} style={{ width: "100%" }} />
        </a>

        <div
          style={{
            background: "white",
          }}
        >
          <div style={{ flexBasis: "100%", height: 40 }} />
          <div
            style={{
              width: "100%",
              margin: "0 auto",
              display: "flex",
              justifyContent: "center",
              fontFamily: "Noto Sans CJK KR",
              fontSize: 15,
              fontWeight: 800,
            }}
          >
            테스트 공유하기
          </div>
          <div style={{ flexBasis: "100%", height: 12 }} />
          <div
            style={{
              width: "100%",
              margin: "0 auto",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              onClick={this.shareFacebook}
              style={{ width: "10%", height: "10%", cursor: "pointer" }}
              src={shareIcon1}
            />
            <div style={{ width: 7 }} />
            <img
              onClick={this.shareTwitter}
              style={{ width: "10%", height: "10%", cursor: "pointer" }}
              src={shareIcon2}
            />
            <div style={{ width: 7 }} />
            <img
              onClick={this.shareKakaotalk}
              style={{ width: "10%", height: "10%", cursor: "pointer" }}
              src={shareIcon3}
            />
            <div style={{ width: 7 }} />
            <img
              onClick={this.shareNaver}
              style={{ width: "10%", height: "10%", cursor: "pointer" }}
              src={shareIcon4}
            />
            <div style={{ width: 7 }} />
            <Link
              to="/result"
              style={{ width: "10%", height: "10%" }}
              onClick={(e) => e.preventDefault()}
            >
              <CopyToClipboard
                text="https://place.scjandrew.net/"
                onCopy={() => alert("링크가 복사되었습니다!")}
              >
                {/*style={{ width: "65%", transform: `translate(25%, 0%)` }}*/}
                <img
                  alt="공유 버튼"
                  style={{ width: "100%", height: "100%", zIndex: "1" }}
                  src={shareIcon5}
                />
              </CopyToClipboard>
            </Link>
          </div>
          <div style={{ flexBasis: "100%", height: 12 }} />
          <div style={{ flexBasis: "100%", height: 20 }} />
          <a
            href="https://quiz.scjandrew.net/"
            style={{
              display: "flex",
              margin: "0 auto",
              width: "70%",
            }}
          >
            <img
              alt="테스트 다시하기"
              src={resultBtn1}
              style={{ width: "100%" }}
            />
          </a>

          <div style={{ flexBasis: "100%", height: 5 }} />
          <Link
            to="/result"
            onClick={(e) => e.preventDefault()}
            style={{
              display: "flex",
              margin: "0 auto",
              width: "70%",
            }}
          >
            <img
              alt="결과 다운로드"
              style={{ width: "100%" }}
              onClick={async () => {
                this.setState({ forCapture: "translate(0%, -80%)" }, () => {
                  window.scrollTo(0, 0);
                  this.getScreenshotHandler();
                });
              }}
              src={resultBtn2}
            />
          </Link>

          <div style={{ flexBasis: "100%", height: 15 }} />
        </div>
      </div>
    );
  }
}

export default Result;
