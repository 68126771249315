import React from "react";
import testImg from "../img/testimg_1.png";
import artIcon1 from "../img/art_test_icon10.png";
import artIcon2 from "../img/art_test_icon2.png";
import artIcon3 from "../img/art_test_icon12.png";
import artIcon4 from "../img/art_test_icon4.png";
import artIcon5 from "../img/art_test_icon14.png";
import historyIcon1 from "../img/history_test_icon4.png";
import historyIcon2 from "../img/history_test_icon2.png";
import historyIcon3 from "../img/history_test_icon6.png";
import historyIcon4 from "../img/history_test_icon7.png";
import historyIcon5 from "../img/history_test_icon10.png";
import scienceIcon1 from "../img/science_test_icon1.png";
import scienceIcon2 from "../img/science_test_icon2.png";
import scienceIcon3 from "../img/science_test_icon8.png";
import scienceIcon4 from "../img/science_test_icon4.png";
import scienceIcon5 from "../img/science_test_icon6.png";
import socialIcon1 from "../img/social_test_icon1.png";
import socialIcon2 from "../img/social_test_icon2.png";
import socialIcon3 from "../img/social_test_icon3.png";
import socialIcon4 from "../img/social_test_icon4.png";
import socialIcon5 from "../img/social_test_icon5.png";
import literatureIcon1 from "../img/literature_test_icon1.png";
import literatureIcon2 from "../img/literature_test_icon2.png";
import literatureIcon3 from "../img/literature_test_icon3.png";
import literatureIcon4 from "../img/literature_test_icon4.png";
import literatureIcon5 from "../img/literature_test_icon5.png";
import religionIcon1 from "../img/religion_test_icon1.png";
import religionIcon2 from "../img/religion_test_icon2.png";
import religionIcon3 from "../img/religion_test_icon3.png";
import religionIcon4 from "../img/religion_test_icon4.png";
import religionIcon5 from "../img/religion_test_icon5.png";

function Quiz(props) {
  let q1,
    q2,
    q3,
    q4 = "",
    q5 = "",
    q6 = "",
    q7 = "",
    q8 = "";
  let quizImg = testImg;
  //역사
  if (props.index * 1 === 2) {
    q1 = "조선 시대 고종의 친아버지로서 오랫동안";
    q2 = "정치를 맡아 보았던 사람은 누구인가?";
    q3 = "";
    quizImg = historyIcon1;
  } else if (props.index * 1 === 3) {
    q1 = "우리나라 국보 제32호인 팔만대장경은";
    q2 = "외적인 침략을 막기 위해 만든 것이다.";
    q3 = "어느 나라의 침입을 막기 위한 것인가?";
    quizImg = historyIcon2;
  } else if (props.index * 1 === 4) {
    q1 = "조선 시대 효종 때 우리나라에서 억류 생활을";
    q2 = "하다가, 본국으로 탈출해 표류기를 써";
    q3 = "서양에 조선을 소개한 사람은 누구인가?";
    quizImg = historyIcon3;
  } else if (props.index * 1 === 5) {
    q1 = "14세기 중엽, 전 유럽에서 발생하여 2,500만 명의";
    q2 = "생명을 앗아 갔던 이 병은 무엇인가?";
    q3 = "";
    quizImg = historyIcon4;
  } else if (props.index * 1 === 6) {
    q1 = "심부름을 간 사람이 소식이 아주 없거나 또는";
    q2 = "회답이 오지 않을 때 '함흥차사'라는 말을 쓴다.";
    q3 = "이 말을 만들어 낸 조선 시대 임금은 누구인가?";
    quizImg = historyIcon5;
  }
  //문학
  else if (props.index * 1 === 8) {
    q1 = "<진달래꽃>으로 유명한 시인으로, 나이 서른";
    q2 = "셋에 요절한 민요 시인은 누구인가?";
    q3 = "";
    quizImg = literatureIcon1;
  } else if (props.index * 1 === 9) {
    q1 = "'어디선가 OOO 냄새가 나요'에서 빈칸에";
    q2 = "들어갈 알맞은 형용사는 무엇인가?";
    q3 = "";
    quizImg = literatureIcon2;
  } else if (props.index * 1 === 10) {
    q1 = "흔히 웃어른께 자녀의 수를 물어볼 때 '슬하에";
    q2 = "자녀가 몇이십니까?'라고 한다. 슬하는 신체의";
    q3 = "한 부분을 가리키는데, 어디를 지칭하는가?";
    quizImg = literatureIcon3;
  } else if (props.index * 1 === 11) {
    q1 = "조나단 스위프트의 유명한 소설 <걸리버";
    q2 = "여행기>에서 주인공 걸리버의 원래 직업은";
    q3 = "무엇인가?";
    quizImg = literatureIcon4;
  } else if (props.index * 1 === 12) {
    q1 = "삼장법사가 온갖 고초를 겪으면서 천축으로 가";
    q2 = "불교의 경전을 구해 온다는 내용의 소설은";
    q3 = "무엇인가?";
    quizImg = literatureIcon5;
  }
  //예술
  else if (props.index * 1 === 14) {
    q1 = "관악기는 만든 재질에 따라 금관 악기와";
    q2 = "목관 악기로 나뉜다. 다음 중 목관 악기가 아닌";
    q3 = "것은 무엇인가?";
    quizImg = artIcon1;
  } else if (props.index * 1 === 15) {
    q1 = "600여 곡이나 되는 가곡을 써서";
    q2 = "'가곡의 왕'이라 불리는 사람은 누구인가?";
    q3 = "";
    quizImg = artIcon2;
  } else if (props.index * 1 === 16) {
    q1 = "다음 중 유네스코가 지정한 세계 문화 유산이";
    q2 = "아닌 것은 무엇인가?";
    q3 = "";
    quizImg = artIcon3;
  } else if (props.index * 1 === 17) {
    q1 = "사람의 목소리는 저마다 다르지만 남성과";
    q2 = "여성의 목소리는 특히 다르다. 그러면 여성의";
    q3 = "가장 높은 소리는 무엇인가?";
    quizImg = artIcon4;
  } else if (props.index * 1 === 18) {
    q1 = "연극 가운데 대사는 한마디도 하지 않고 표정";
    q2 = "이나 몸짓만으로 생각이나 감정을 표현하는";
    q3 = "형식이 있다. 무언극, 묵극이라고 하는 이것은";
    q4 = "무엇인가?";
    quizImg = artIcon5;
  }
  //과학
  else if (props.index * 1 === 20) {
    q1 = "우리 몸의 췌장에서 만들어지는 호르몬으로,";
    q2 = "혈당의 양을 조절하여 당뇨병과 관계있는 것은";
    q3 = "무엇인가?";
    quizImg = scienceIcon1;
  } else if (props.index * 1 === 21) {
    q1 = "우리 몸 안에서 생긴 액체 상태의 찌꺼기를 걸러";
    q2 = "물과 함께 오줌으로 만드는 기관은 어느 곳인가?";
    q3 = "";
    quizImg = scienceIcon2;
  } else if (props.index * 1 === 22) {
    q1 = "대기는 맨 아래층인 대류권부터 성층권,";
    q2 = "중간권, 열권, 외기권으로 나뉜다. 이 중";
    q3 = "비와 바람과 눈이 생겨나는 층은 어디인가?";
    quizImg = scienceIcon3;
  } else if (props.index * 1 === 23) {
    q1 = "자신이 고안한 천체만원경으로 목성도 위성을";
    q2 = "가지고 있는 사실을 처음 관찰한 과학자는";
    q3 = "누구인가?";
    quizImg = scienceIcon4;
  } else if (props.index * 1 === 24) {
    q1 = "태양은 거센 불덩이가 활활 타오르고";
    q2 = "있는 항성이다. 태양의 표면 온도는";
    q3 = "몇 도쯤 되는가?";
    quizImg = scienceIcon5;
  }
  //사회
  else if (props.index * 1 === 26) {
    q1 = "식약처에서 인증한 보건용 마스크 제품에는";
    q2 = "'KF80', 'KF94' 등이 표기되어 있는데";
    q3 = "이는 미세입자의 차단 성능을 의미하는 것으로,";
    q4 = "숫자가 클수록 더 작은 입자를 더 많이 막아낸다는";
    q5 = "뜻이다. 그렇다면 'KF'는 무엇의 약자인가?";
    quizImg = socialIcon1;
  } else if (props.index * 1 === 27) {
    q1 = "1999년, 한 신문 기사에는 3C맨이 된";
    q2 = "만년 대리들의 고충에 관한 내용이 실렸다.";
    q3 = "3C맨은 카피, 커피, 이것 맨을 이르는 ";
    q4 = "당시 유행어로 복사, 커피 심부름은 기본이고";
    q5 = "일까지 이 동물처럼 해야 한다는 의미였다.";
    q6 = "이 동물은 무엇인가?";
    quizImg = socialIcon2;
  } else if (props.index * 1 === 28) {
    q1 = "2014년 홍콩에서 일어난 민주화 시위를";
    q2 = "일명 '이것'혁명이라고 부른다. ";
    q3 = "당시 최루탄과 물대포를 이용한 경찰의 공격에";
    q4 = "시민들이 이것을 방패 삼아 막아낸 데서";
    q5 = "이름 붙여졌는데, 이것은 최근, 홍콩 범죄인";
    q6 = "인도법 반대 시위에서도 등장하며 홍콩 사회에서 ";
    q7 = "'저항'의 상징물이 되었다. 이것은 무엇인가?";
    quizImg = socialIcon3;
  } else if (props.index * 1 === 29) {
    q1 = "베트남 하노이에서 세계 최초의 (      ) ATM기가";
    q2 = "등장해 화제다. 코로나19로 일자리를 잃고";
    q3 = "생계난을 겪는 시민들에게 (      )을 무료로";
    q4 = "제공하고 있는데, 전 세계에서 베트남이";
    q5 = "세 번째로 많은 수출하는 (      )은 무엇인가?";
    quizImg = socialIcon4;
  } else if (props.index * 1 === 30) {
    q1 = "오늘날 전 세계에서 사용하는 이것은 ";
    q2 = "지난 2007년 IT개발자였던 크리스 메시나에";
    q3 = "의해 탄생했다. 그는 프로그래밍 C언어에서";
    q4 = "우선 처리해야 하는 명령어 앞에 특정기호를";
    q5 = "붙였던 방식에서 이것에 영감을 얻었다고 하는데,";
    q6 = "게시물의 분류와 검색을 편안하게 하기 위한 ";
    q7 = "목적으로 하루 평균 1억 건 이상 사용되는";
    q8 = "'이것'은 무엇인가?";
    quizImg = socialIcon5;
  }
  //종교
  else if (props.index * 1 === 32) {
    q1 = "그리스 로마 신화의 영웅으로서 완벽한";
    q2 = "사람의 치명적인 약점을 뜻하는 말의 어원이";
    q3 = "되는 장수의 이름은?";
    quizImg = religionIcon1;
  } else if (props.index * 1 === 33) {
    q1 = "원시 공동사회 종교의 한 형태로 동식물이나";
    q2 = "자연물과 공통의 기원을 갖는다고 믿고 집단의";
    q3 = "상징으로 삼고 숭배하는 것은?";
    quizImg = religionIcon2;
  } else if (props.index * 1 === 34) {
    q1 = "12월25일 크리스마스는 본래 어떤 신을";
    q2 = "섬기는 날인가? 정복되지 않는 OOO의";
    q3 = "탄생일이다.";
    quizImg = religionIcon3;
  } else if (props.index * 1 === 35) {
    q1 = "부처님을 의미하는 상상의 꽃이란 뜻으로";
    q2 = "3천년에 한 번 피는 이 꽃의 이름은?";
    q3 = "";
    quizImg = religionIcon4;
  } else if (props.index * 1 === 36) {
    q1 = "'서기 2020년'에서 서기는 무엇을";
    q2 = "뜻하는가?";
    q3 = "";
    quizImg = religionIcon5;
  }
  return (
    <div style={{ transform: `translate(0%, -15%)` }}>
      <div>
        <br />
        <img
          alt="퀴즈 이미지"
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "0 auto",
            width: "30%",
          }}
          src={quizImg}
        />
        <br />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "0 auto",
          flexWrap: "wrap",
          fontFamily: "Noto Sans CJK KR",
          fontWeight: 500,
        }}
      >
        <div>{q1}</div>
        <div style={{ flexBasis: "100%", height: 0 }}></div>
        <div>{q2}</div>
        <div style={{ flexBasis: "100%", height: 0 }} />
        <div>{q3}</div>
        <div style={{ flexBasis: "100%", height: 0 }} />
        <div>{q4}</div>
        <div style={{ flexBasis: "100%", height: 0 }} />
        <div>{q5}</div>
        <div style={{ flexBasis: "100%", height: 0 }} />
        <div>{q6}</div>
        <div style={{ flexBasis: "100%", height: 0 }} />
        <div>{q7}</div>
        <div style={{ flexBasis: "100%", height: 0 }} />
        <div>{q8}</div>
      </div>
      <br />
    </div>
  );
}

export default Quiz;
